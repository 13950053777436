.contact-hero{
    position: relative;
    padding: 0;
    width: 100%;
    height: 300px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contact-content{
    position: relative;
    .maps-kantor{
        position: relative;
        width: 100%;
        height: 400px;
    }
    .form-control{
        padding: 1rem 1.25rem;
        border-radius: 0;
        margin-bottom: 1rem;
        &:focus{
            outline: none;
            box-shadow: none;
            border: 1px solid #0D2143;
        }
    }
    .section-body{
        position: relative;
        .head-title{
            font-size: 24px;
            font-weight: 700;

            color: #0D2143;
        }
        .contact-person{
            li{
                padding: 10px 0;
                a{
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    i{
                        margin-right: 1rem;
                        width: 40px;
                        height: 40px;
                        background-color: #0D2143;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        font-size: 20px;
                    }
                    span{
                        color: #0D2143;
                    }
                    &:hover{
                        i{
                            background-color: #FFFFFF;
                            color: #0D2143;
                            border: 1px solid #0D2143;
                        }
                    }
                }
            }
        }
    }
    .section-heading{
        .title-form{
            font-size: 26px;
            color: #0D2143;
        }
    }
}