.about-hero{
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
    .owl-carousel{
        display: flex;
        align-items: center;
        .owl-item{
            .item{
                position: relative;
                width: 100%;
                height: 500px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
    .about-head{
        position: absolute;
        width: 100%;
        height: 500px;
        color: #FFFFFF;
        background-color: #0D214320;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
    }
}
.content-about{
    position: relative;
    width: 100%;
    .section-head{
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            bottom: -10px;
        }
    }
    .section-body{
        position: relative;
        .desc-about{
            font-size: 20px;
        }
    }

    .card{
        position: relative;
        padding: 16px;
        border: 1px solid #d9b07a;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        background-color: #d9b07a;
        .card-header{
            padding: 0;
            background-color: transparent;
            text-align: center;
            border-radius: 0;
            border: 0;
            font-size: 20px;
            font-weight: bold;
            color: #0D2143;
            font-size: calc(1.3rem + .6vw);
            border-bottom: 1px solid #0D2143;
            padding-bottom: 1rem;
        }
        .card-body{
            padding-top: 1rem;
            text-align: center;
            font-size: 18px;
            color: #0D2143;
        }
    }
}
.our-company{
    .section-head{
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            bottom: -10px;
        }
    }
}
section{
    &.section-list{
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .desc-about{
            font-size: 20px;
        }
        &.bg-section{
            h4{
                color: #DB8132;
            }
            .desc-about{
                color: #FFFFFF;
            }
        }
        .box-image{
            video{
                position: relative;
                width: 100%;
            }
            .owl-item{
                .item{
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}