.career-hero{
    position: relative;
    padding: 0;
    width: 100%;
    height: 300px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.list-career{
    position: relative;
    .section-head{
        .title{
            font-size: 30px;
            color: #0D2143;
            margin-bottom: 1rem;
        }
        .desc{
            color: #000000;
            font-size: 18px;
        }
        &::after{
            content: none;
        }
    }
    .section-body{
        position: relative;
        margin-top: 3rem;
        .card{
            &.lift{
                -webkit-backface-visibility: hidden;
                -moz-backface-visibility: hidden;
                transform: translateY(0);
                transition: all .3s ease;
                .avatar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    line-height: 1.7;
                    letter-spacing: -.01rem;
                    border-radius: 100%;
                    font-size: 1rem;
                    width: 3rem;
                    height: 3rem;
                }
                .bg-pale-blue {
                    background-color: #0d214327;
                }
                .text-blue{
                    color: #0D2143;
                }
                .text-body{
                    color: #60697b;
                }
            }
            .card-body{
                padding: 1.25rem !important;
            }
        }
    }
}