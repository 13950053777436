.hero{
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
    .owl-carousel{
        height: 100%;
        display: flex;
        align-items: center;
        .owl-stage-outer{
            height: 100%;
            .owl-stage{
                height: 100%;
                .owl-item{
                    height: 100%;
                    .item{
                        position: relative;
                        width: 100%;
                        height: 961px;                
                    }
                }
            }
        }
        .owl-dots{
            position: absolute;
            margin: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(90deg);
            background-color: #0D2143;
            border-radius: 20px;
        }
    }
}

@media screen and (max-width: 961px) {
    .home {
        .owl-theme {
            .owl-dots {
                .owl-dot {
                    span {
                        width: 5px;
                        height: 5px;
                    }
                }
            }
        }
    }
}

.about{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(212, 175, 123, 0.1);
    .half{
        width: 50%;
    }
    .full-wrap{
        width: 100%;
        position: relative;
        .img{
            height: 990px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    .text-about {
        max-width: 1280px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin: 0 auto!important;
    }
    .section-head{
        position: relative;
        &::after{
            left: 0;
        }
        h2{
            color: #0D2143;
        }
    }
    .section-body{
        p{
            font-size: 20px;
        }
    }
    &.bg-circle{
        &::before{
            left: 0;
            bottom: 0;
            position: absolute;
            z-index: -1;
            background-image: url(../img/circle.webp);
            width: 654px;
            height: 389px;
        }
    }
}

@media (max-width: 767.98px){
    .about{
        .half {
            width: 100%;
        }
        .full-wrap{
            .img {
                height: 500px;
                background-size: cover;
            }
        }
        .text-about {
            position: relative;
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
            transform: translateY(0);
            padding: 3em 0;
        }
    }
}

.brands{
    position: relative;
    height: auto;
    .section-head{
        &::after{
            left: 0;
        }
        h2{
            color: #0D2143;
        }
    }
    .section-body{
        position: relative;
        .description{
            font-size: 20px;
            color: #0D2143;
        }
        .item{
            padding: 1rem;
        }
        .box-logo{
            align-items: center;
            display: flex;
            width: 100%;
            height: 170px;
            padding: 1rem;
            justify-content: center;
            position: relative;
            background-color: #FFFFFF;
            border-radius: 15px;
        }
    }
    .box{
        position: relative;
        border: 1px solid #0D2143;
        border-radius: 15px;
        &:hover{
            .box-desc{
                background-color: #DB8132;
            }
        }
        .box-logo{
            position: relative;
            padding: 10px;
            margin: 0 auto;
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .box-desc{
            position: relative;
            text-align: center;
            background-color: #0D2143;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            padding: 16px 0;
            .brand-title{
                font-size: 20px;
                color: #FFFFFF;
                padding-bottom: 16px;
            }
            .btn-visit{
                position: relative;
                padding: 0;
                margin: 0 auto;
                padding: 10px;
                border: 1px solid #FFFFFF;
                border-radius: 10px;
                color: #FFFFFF;
            }
        }
    }

    .owl-nav{
        button{
            position: absolute;
            top: 50%;
            width: 30px;
            height: 30px;
            border-radius: 100% !important;
            background-color: #0D2143 !important;
            &.owl-prev{
                left: -20px;
            }
            &.owl-next{
                right: -20px;
            }
        }
    }
    .owl-prev i, .owl-next i {transform : scale(1,1); color: #ccc;}
}
@media (max-width: 767.98px){
    .brands{
        .owl-nav{
            button{
                &.owl-prev{
                    left: 0px;
                }
                &.owl-next{
                    right: 0px;
                }
            }
        }
    }
}

.career{
    position: relative;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    z-index: 0;
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        z-index: -1;
        opacity: .8;
        background-color: #0D2143;
    }
    .section-head{
        position: relative;
        &::after{
            content: none;
        }
        h2{
            color: #DB8132;
        }
        .title{
            font-size: 30px;
            color: #DB8132;
            font-weight: 600;
        }
    }
    .section-body{
        position: relative;
        margin: 2rem 0;
        p{
            font-size: 18px;
            color: #FFFFFF;
        }
        img{
            width: 100%;
            border-radius: 40px 40px 240px 40px;
        }
    }
    .btn-more{
        padding: 1rem 1.25rem;
        border-radius: 10px;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        &:hover, &:focus{
            background-color: #000000;
            color: #FFFFFF;
            outline: none;
            box-shadow: none;
        }
    }
}