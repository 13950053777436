footer{
    position: relative;
    width: 100%;
    color: #0D2143;
    .footer-first{
        padding: 4rem 0;
        background-color: #dfdfdf;
        .title{
            font-size: 26px;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .desc{
            font-size: 18px;
            padding-bottom: 10px;
        }
        .alamt{
            li{
                display: flex;
                align-items: center;
                padding-bottom: 16px;
                i{
                    width: 45px;
                    height: 45px;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #DB8132;
                    border-radius: 100%;
                }
                span{
                    margin-left: 10px;
                }
                &:last-child{
                    padding-bottom: 0;
                }
            }
        }
        .menu{
            li{
                padding-bottom: 20px;
                a{
                    font-size: 20px;
                    color: #0D2143;
                }
                &:last-child{
                    padding-bottom: 0;
                }
            }
        }
    }
    .footer-second{
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0D2143;
        p{
            padding: 0;
            margin: 0;
            color: #FFFFFF;
            font-size: 18px;
            a{
                color: #FFFFFF;
            }
        }
    }
}

@media screen and (max-width: 961px){
    footer {
        .footer-first {
            .menu {
                li {
                    a {
                        font-size: 16px;
                    }
                }
            }
            .title{
                font-size: 18px;
            }
            .desc{
                font-size: 16px;
            }
        }
    }
}