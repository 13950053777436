@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import './base/base';

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

a{
    text-decoration: none;
}

body{
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: normal;
    background-color: transparent;
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6{
    font-weight: 700;
}

.wrapper{
    position: relative;
    width: 100%;
    height: 100%;
}
section{
    padding: 8rem 0;
    position: relative;
    width: 100%;
    height: 100vh;
}
h1,h2,h3,h4,h5,h6,p,span,small,strong,ul,li{
    padding: 0;
    margin: 0;
}

.section-heading{
    .title-heading{
        color: #0D2143;
        &::after {
            content: "";
            height: 5px;
            width: 50px;
            display: inline-block;
            border-radius: 5px;
            bottom: 26px;
            margin: 5px 0 5px 10px;
            background-color: #DB8132;
            background-color: hsl(28, 70%, 53%);
            background-color: rgb(219, 129, 50);
            background-color: hwb(28 20% 14%);
        }
    }
}

.section-head{
    position: relative;
    &::after{
        position: absolute;
        bottom: -10px;
        width: 100px;
        height: 10px;
        border-radius: 20px;
        content: '';
        background: #DB8132;
    }
}

.firstHeading{
    border: 0!important;
    clip: rect(0 0 0 0)!important;
    height: 0.0625rem!important;
    margin: -0.0625rem!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    width: 0.0625rem!important;
}

.btn-consult{
    background-color: #0D2143;
    color: #FFFFFF;
    border-radius: 0;
    padding: 1rem 1.75rem;
}
.btn-more{
    position: relative;
    padding: 1rem 1.25rem;
    border-radius: 10px;
    border: 1px solid #0D2143;
    color: #0D2143;
    background-color: #ffffff;
    &:hover, &:focus{
        background-color: #0d2143;
        color: #FFFFFF;
        outline: none;
        box-shadow: none;
    }
}

.btn-more_1{
    padding: 1rem 1.25rem;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    &:hover, &:focus{
        background-color: #FFFFFF;
        color: #0D2143;
        outline: none;
        box-shadow: none;
    }
}

.bg-section{
    background-color: #0D2143;
}