.brands-hero{
    position: relative;
    padding: 0;
    width: 100%;
    height: 300px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.list-brand{
    position: relative;
    .section-heading{
        p{
            font-size: 20px;
        }
    }
    .box{
        position: relative;
        border: 1px solid #0D2143;
        border-radius: 15px;
        &:hover{
            .box-desc{
                background-color: #DB8132;
            }
        }
        .box-logo{
            position: relative;
            padding: 10px;
            width: 100%;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .box-desc{
            position: relative;
            text-align: center;
            background-color: #0D2143;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            padding: 16px 0;
            .brand-title{
                font-size: 20px;
                color: #FFFFFF;
                padding-bottom: 16px;
            }
            .btn-visit{
                position: relative;
                padding: 0;
                margin: 0 auto;
                padding: 10px;
                border: 1px solid #FFFFFF;
                border-radius: 10px;
                color: #FFFFFF;
            }
        }
    }
}