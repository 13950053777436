#header{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    background-color: hsla(0,0%,100%,.8);
    transition: background-color .5s cubic-bezier(.28,.11,.32,1);
    transition-property: background-color,-webkit-backdrop-filter;
    transition-property: background-color,backdrop-filter;
    transition-property: background-color,backdrop-filter,-webkit-backdrop-filter;
    .navbar{
        position: relative;
        padding: .75rem 0;
        .navbar-brand{
            position: relative;
            margin: 0;
            padding: 0;
            img{
                width: 100%;
                height: 60px;
            }
        }
        .navbar-collapse{
            .navbar-nav{
                .nav-item{
                    margin: 0 1rem;
                    .nav-link{
                        padding: 0;
                        color: #0D2143;
                        text-transform: uppercase;
                        font-weight: 500;
                        &.active{
                            color: #DB8132;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        .navbar-toggler{
            border: 0;
            i{
                color: #0D2143;
            }
            &:hover, &:active{
                outline: none;
                box-shadow: none;
            }
        }
    }
}

@media screen and (max-width: 961px){
    #header {
        .navbar {
            .navbar-collapse{
                display: block;
                height: 100vh;
                right: 0;
                margin: 0;
                padding: 1rem;
                position: fixed;
                transform: translateX(100%);
                transition: all 0.1s cubic-bezier(0.37, 0.15, 0.32, 0.94) 0s;
                width: 90%;
                z-index: 9;
                background-color: #FFFFFF;
                .navbar-nav{
                    .nav-item{
                        margin: 1rem;
                    }
                }
                &.show{
                    transform: translateX(0px);
                }
            }
        }
    }
    
}